<template>
    <div>
        <p class="cursor" @click="valider">Valider l'Installation de {{ cleanProprio }} ✅</p>
        <p class="cursor" @click="invalider">Invalider l'Installation de {{ cleanProprio }} 🛡️</p>
        <p class="submit" v-if="error === true">Erreur rencontée !</p>
        <p class="submit" v-else-if="error === false">Opération réalisée avec succès !</p>
    </div>
</template>

<script>
export default {
    name: 'admin',
    components: {
    },
    props: {
        proprio: {
            type: String,
            default: null
        }
    },
    data() {         
        return {
            error: null,
            apiUrl: this.apiUrl = "/backend/get/admin/valider"
        }
    },
    computed: {
        cleanProprio: function() {
            return this.proprio.replace(/___/gi,' ')
        },
        getStatus: function() {
            return localStorage.getItem('admin')
        }
    },
    methods : {
        valider: function() {
            this.apiUrl = "/backend/get/admin/valider"
            this.submit()
        },
        invalider: function() {
            this.apiUrl = "/backend/get/admin/invalider"
            this.submit()
        },
        submit: function() {
            this.error = null
            
            fetch(this.apiUrl,{
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({admin: this.getStatus, proprio: this.cleanProprio})
            })
            .then(res => res.json())
            .then(res => {                
                if(res.action != "done") {
                    this.error = true                        
                }
                else if(res.action === "done") {            
                    this.error = false
                }                       
            })
        }  
    }
}
</script>


<style scoped>
div {
    border:1px solid #222;
    border-radius:5px;
    margin:0 auto;
    margin-bottom:5px;
    width:90%;
    background:lightcoral;
    color:#222;
}

.cursor {
    cursor:pointer;
}

.submit {
    font-style: italic;
}
</style>