<template>
    <div id="details" class="shadow whitebg">
      <h2>Les données</h2>
        <div v-for="details in json" v-bind:key=details.ID>
          <h3 class="roomType" v-if=" details.Dedier == 'oui'">Salle dédiée</h3>
          <h3 class="roomType" v-else>Pièce de vie</h3>              
          <p v-if="details.text_budget!='---'">Budget {{ details.text_budget.toLowerCase() }}</p>
          <!--<p>Pays : {{ details.Pays }}</p>-->
          <p v-if="details.conf_hp!='---.---'">Configuration HP : {{ details.conf_hp }}</p>
          <p v-if="details.surface!='---'">Surface  : {{ details.surface }} m²</p>
          <p>Postée le {{ details.add_time }}, mise à jour le  {{ details.valide_le }}</p>     
          <p>Vue {{ details.nb_vu }} fois</p>          
        </div>      
    </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'detailsInstallation',
  props: {
    proprio: String
  },
  data() {         
    return {
      json: null      
    }    
  },
  mounted() {
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/installation/details/proprio/' + this.proprio)
    .then(response => (this.json = response.data.details))    
  }     
}
</script>

<style scoped>
#details {
    text-align:left;
    padding:10px;
    margin:10px;
    border:1px solid #eee;
    border-radius:5px;
}

h2 {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAACRUlEQVRYhe2WsWsUQRTGf55oxERrI8GAnoJgEExzBixMIYKeSP4CLcVKFJOAWogI+k94NgGL9Emh6eW0SKNoEW2VKHpGMEHXYr9hnuPM3m7OKIofDLP7vTffe3tv5s3Bf/yleANkwXj7u4IPR4K7MVxVrLaOBEY1zwGbNOYD24YlsAU4peenhn+i+bR8esZeoA1cAw6IOwu8xv/cE8Z/wvCvgDPi90ujLc3SmOXH2r40z8+Ai0C/8e8X99z4vQg0ZssGH9eCj0ALeK/3L8Al8pqnUAMuyzfT2pa0MmkXYjOwKOcpcVvJa3u07BcAY0AT6NP7tDQXFSOJC3JcA4YK/JrAAvBJ45GSTGFImpliJHEQWMLXsB7xuU26D9yK+Nfxe2hJMQqxi3zXZuQdzybRxO+Hq8BujUl83e0vUcd3zba0S2EAWNbCEcMviJuMrJmS7aHhRsQtS7MSVrR4p+E64gYj/oP40+OwQ9xKKkhRJ8w022P3VfNqxH8t8LHP20kc31QCA+Q1Bdhj+Lbm85E15zQ/Ntxn/H2RhQtSKNqEJ/Gb8IjhD4v7BpwI9Gp0OfsW4THcF/G5AdyJiN8ErkeC3wNmyibhGtEqxY2ojLizux5RKgnbiqfF9ZGf/7EK4tbeAT5UScJdRh3gPv4ycl2um3hoPwY0jN+VbgnAz9fx3UTwUHwmYndoAA+AbWUSiP0hiQW34i6JmL1n1MjvddfVjkd8RoF3Bfaegqe+PESji31dwVsUf/mG4pAC//KaVsH4nwz+7+M75xnOgQ+MGAoAAAAASUVORK5CYII=) no-repeat;
  background-size: 20px;
  padding-left: 25px;
  font-size: 1.1rem;
  font-weight: bold;  
}

p {
  margin-left:20px;
  margin-bottom:5px;
}

h3 {
  padding-left:10px;
  font-size: 1rem;
  font-weight: bold;
  color:#007BFF;
}
</style>