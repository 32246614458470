<template>
    <div id="materiel" class="shadow whitebg">
      <h2>Matériel</h2>      
      <div v-for="materiel in json" v-bind:key=materiel.ID>           
        <div v-if="materiel.noInfo">
          <h3 >{{ materiel.noInfo }}</h3>
        </div>
        <div v-else>
        <h3 v-if="materiel.Texte_Cat_Display">{{ materiel.Texte_Cat }}</h3>
          <p>{{ materiel.Texte_Fonc }} {{ materiel.Texte_Marq }} {{ materiel.designation }}</p>        
        </div>
      </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'materiel',
  props: {
    proprio: String    
  },
  data() {         
    return {
      json: null,
      sectionInProgress: null
    }    
  },
  mounted() {
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/installation/materiel/proprio/' + this.proprio)
    .then(response => (this.json = response.data.materiel))    
  },
  computed:{
  },
  methods:{
  }
}
</script>

<style scoped>
#materiel {
    text-align:left;
    padding:10px;
    margin:10px;
    border:1px solid #eee;
    border-radius:5px;
}
h2 {
  background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAABE0lEQVRIie2Vvw7BQBzHP0RMEovB7AUsJnSy8AA2s1fwCnajd7CZJQSPIcFisAkJ8WdoLznX07tWa5B+ku/Q5O73+fXXawspdlSAtZfKr6QOcACeXo5AK2lpH7hKUpEbMEhCmANGGqGaMZCPS1oCZhZSkSVQ/lZaBTYhpCJ7oBZV2gVOEaQiF6AXRpjBPSj3L6QiD2AIZE3SAjCJQahmChSDxKsEpCIrWaSO4KxppoE7/jBxNHV0tX3InYL7iojrhU0BTY03jA/d46EUTAy522aE/Q6GO84EiOPE57Ed9c+Qx1SPsL+JYdQ2YkhPtZn0VPv4JN5b7G2g/yar7OzbgY634dOfZi6tXQSs2wLtMOKU/+EFrTnBHM14D8sAAAAASUVORK5CYII=) no-repeat;
  background-size:20px;
  padding-left:25px;
  font-size: 1.1rem;
  font-weight: bold;  
}

h3 {
  padding-left:10px;
  font-size: 1rem;
  font-weight: bold;
  color:#007BFF;
}

p {
  margin-left:20px;
  margin-bottom:5px;
}

</style>