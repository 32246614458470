<template>
    <div class="randomPix">    
      <div class="wrapper">
        <div v-for="randompix in json" v-bind:key=randompix._ID class="item">
          <router-link class="polaroid" :to="{path: '../installation/' + proprio }">
            <img :src="$protocolServer + '://' + $urlServer + '/backend/get/image/voir/' + randompix.vignette + '/2'" :alt="'Installation de ' + randompix.Proprio"/>
            <div class="caption">Home-Cinema de  {{ cleanProprio }}</div>
          </router-link>
        </div>
      </div>
    </div>    
</template>

<script>
import axios from 'axios'
export default { 
  name: 'randomPix',  
  data() {         
    return {
      json: null      
    }    
  },
  props: {
    proprio: {
      type: String,
      default: null
    }    
  },
  mounted() {
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/installation/randompix/' + this.proprio)
    .then(response => {
      this.json = response.data.randompix;
    })    
  },
  computed: {
    cleanProprio: function() {
      return this.proprio.replace(/___/gi,' ')
    }
  }
}
</script>

<style scoped>


.wrapper {
  width: 100%;
  padding: 0 2rem;
  text-align: center;
}

.polaroid {
  background: #fff;
  padding: 1rem;
  box-shadow: 0 0.2rem 1.2rem rgba(0,0,0,0.2);
  display:block;  
}
.polaroid > img{
  width:100%;
  max-width: 100%;
  height: auto;
}
.caption {
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.8em;
}
.item {
  /* width: 99%; */
  display: inline-block;
  margin-top: 0.5rem;
  filter: grayscale(100%);
}
.item .polaroid:before {
  content: '';
  position: absolute;
  z-index: -1;
  transition: all 0.35s;
}
.item:nth-of-type(4n+1) {
  transform: scale(0.8, 0.8) rotate(5deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n+1) .polaroid:before {
  transform: rotate(6deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  right: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0,0,0,0.4);
}
.item:nth-of-type(4n+2) {
  transform: scale(0.8, 0.8) rotate(-5deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n+2) .polaroid:before {
  transform: rotate(-6deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  left: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0,0,0,0.4);
}
.item:nth-of-type(4n+4) {
  transform: scale(0.8, 0.8) rotate(3deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n+4) .polaroid:before {
  transform: rotate(4deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  right: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0,0,0,0.3);
}
.item:nth-of-type(4n+3) {
  transform: scale(0.8, 0.8) rotate(-3deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n+3) .polaroid:before {
  transform: rotate(-4deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  left: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0,0,0,0.3);
}
.item:hover {
  filter: none;
  transform: scale(1, 1) rotate(0deg) !important;
  transition: all 0.35s;
}
.item:hover .polaroid:before {
  content: '';
  position: absolute;
  z-index: -1;
  transform: rotate(0deg);
  height: 90%;
  width: 90%;
  bottom: 0%;
  right: 5%;
  box-shadow: 0 1rem 3rem rgba(0,0,0,0.2);
  transition: all 0.35s;
}

a:hover {
  text-decoration: none;
}

h1 {
  padding: 0 55px;
  font-size: 1.05em;
  font-weight: bold;
  margin-top:10px;
  margin-bottom:0;
}
</style>
