<template>
  <div v-if="notHidden === false">
    <p>
      Non disponible pour le moment !
    </p>
  </div>
  <div v-else class="presentation container-fluid min-vh-100">
    <vue-headful
      :title="'Install-HC - Présention de l\'Installation Home-Cinema de ' + cleanProprio"
      :description="cleanProprio + ' nous présente son Installation Home-Cinema'"
    />
    <checkDisplayed :proprio="proprio" @isDisplayed="isDisplayed"/>
    <div>
      <nav role="navigation">
        <div id=header class="float-right">
          <InstallHC/>
        </div> 
        <Menu/>
      </nav>
    </div>
    <div class="picture">
      <!-- <h1>Présentation de l'Installation Home-Cinema de {{ this.cleanProprio }}</h1>       -->

      <div class="Content row">
        <div class="col-md">
          <Vignette :proprio="this.proprio"/>                  
        </div>
        <div class="col-md">
          <ProprioDesc :proprio="this.proprio"/>      
          <Details :proprio="this.proprio"/>                          
        </div>        
      </div>

      <div class="sections">
        <router-link :to="{path: '../installation/' + this.proprio }">
          Consulter les photos
        </router-link>
        <router-link :to="{path: '../commentaires/' + this.proprio }">
           Lire les commentaires
        </router-link>
        <Favori  :proprio="this.proprio"/>
      </div>   

      <div class="Content row">
        <div class="col-md">
          <Materiel :proprio="this.proprio"/> 
        </div>         
        <div class="col-md">
          <RandomPix :proprio="this.proprio"/>                          
        </div>        
      </div>

      <Admin v-if="getStatus === 'true'" :proprio="this.proprio"/>
      <div v-if="getNom == cleanProprio" class="link2mod" ><router-link to="/share">Modifier ou mettre à jour mon Installation-HC 👉🏻</router-link></div>              
      <Pub/>      
      <Description :proprio="this.proprio"/>       
    </div>    
  </div>  
</template>

<script>
// @ is an alias to /src
import Menu from '@/components/Menu.vue'
import Favori from '@/components/Favori.vue'
import Details from '@/components/Presentation/Details.vue'
import ProprioDesc from '@/components/Presentation/Proprio.vue'
import Description from '@/components/Presentation/Description.vue'
import Materiel from '@/components/Presentation/Materiel.vue'
import Vignette from '@/components/Presentation/Vignette.vue'
import RandomPix from '@/components/Presentation/RandomPix.vue'
import InstallHC from '@/components/InstallHC.vue'
import Admin from '@/components/Admin.vue'
export default { 
  name: 'presentation',
  components: {
    Menu,
    Favori,
    Details,
    ProprioDesc,
    Description,
    Materiel,
    Vignette,
    InstallHC,
    Admin,
    RandomPix
  },
  data() {         
    return {
      json: null, 
      windowHeight: 0,
      txt: '',
      actualSize: 0,
      target: '',
      notHidden: true
    }    
  },
  props: {
    proprio: {
      type: String,
      default: null
    }    
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
     this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    }
  },    
  mounted() {
    this.actualSize = window.innerHeight    
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowHeight = window.innerHeight;
        this.actualSize = (window.innerHeight-117)
      });
    })   
  },
  computed: {
    cleanProprio: function() {
      return this.proprio.replace(/___/gi,' ')
    },
    getStatus: function() {
      return localStorage.getItem('admin')
    },
    getNom: function() {
      return localStorage.getItem('login')
    },
  },
  methods: {
    isDisplayed: function(e) {
      this.notHidden = e
    }
  }
}

</script>

<style scoped>
.presentation {
  margin:0 auto;  
  width:100%;    
  padding:0;
}

.debug {
  display: none;
}

.picture {
  width:100%;
  overflow:hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

h1 {
  padding: 0 55px;
  font-size: 1.1rem;
  font-weight: bold;
}

a {
  font-weight: bold;
  font-size:1.1rem;
  display:inline-block;
  padding:0 30px;
}

a:link {
  text-decoration: none;
}

.link2mod {  
  border: 1px solid #222;
  border-radius: 5px;
  margin: 0 auto;
  margin-top:5px;
  margin-bottom:10px;
  width: 90%;
  background: lightcoral;
  color: #222;
}
</style>