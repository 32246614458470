<template>
    <div class="description graybg shadow">
      <div v-for="details in json" v-bind:key=details.ID>    
        <h2>{{ details.Titre }}</h2>        
        <p v-html="details.Intro">{{ details.Intro }}</p>
        <p v-html="details.Conclusion">{{ details.Conclusion }}</p>
      </div>      
    </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'descriptionInstallation',
  props: {
    proprio: String
  },
  data() {         
    return {
      json: null      
    }    
  },
  mounted() {
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/installation/details/proprio/' + this.proprio)
    .then(response => (this.json = response.data.details))    
  }     
}
</script>

<style scoped>
.description {
    text-align:left;
    padding:10px;
    /* background: #f5f5f5;*/
    margin-bottom:10px;
}

h2 {
  font-size: 1.1rem;
  font-weight: bold;  
}
</style>